@import "../common/variables";
.modal {
    overflow: hidden;
}

.modal-backdrop {
    background-color: $color-blue;
    -webkit-filter: blur(15px);
    -moz-filter: blur(15px);
    -o-filter: blur(15px);
    -ms-filter: blur(15px);
    filter: blur(15px);
}

.block-details-model {
    &.modal-dialog {
        max-width: 1090px;
        width: calc(100vw - 30px);
        margin: auto;
        min-height: 100%;
        .modal-body {
            padding-top: 0;
            padding-bottom: 0;
            max-height: 75vh;
            overflow: hidden;
            overflow-y: auto;
        }
    }
}

.modal-dialog {
    .modal-content {
        border-radius: 10px;
        border: 1px solid $color-gray;
        overflow: hidden;
    }
    .modal-header {
        border-bottom: 1px solid $color-gray;
        h5 {
            font-weight: bold;
            font-size: 20px;
            line-height: 26px;
        }
    }
}

.model-table {
    .data-label {
        width: 210px;
    }
    .data-row {
        width: 100%;
        &.half-row {
            width: 50%;
            &:nth-child(odd) {
                padding-right: 16px;
                border-right: 1px solid $color-gray;
            }
            &:nth-child(even) {
                padding-left: 16px;
            }
        }
        &:last-child {
            border-bottom: none;
        }
    }
}

@media(max-width: 1199px) {
    .modal-dialog {
        .modal-header {
            h5 {
                font-size: 18px;
                line-height: 24px;
            }
        }
    }
}

@media(max-width: 991px) {
    .model-table {
        .data-label {
            width: 100%;
            margin-bottom: 5px;
        }
    }
}

@media(max-width: 767px) {
    .model-table {
        .data-row {
            &.half-row {
                width: 100%;
                &:nth-child(odd) {
                    padding-right: 0;
                    border-right: none;
                }
                &:nth-child(even) {
                    padding-left: 0;
                }
            }
        }
        .copy {
            width: 100%;
            margin-top: 5px;
            text-align: right;
        }
    }
}