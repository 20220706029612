@import "../common/variables";
// .pricing-box {
//     border-right: 1px solid $color-gray;
// }

.variation-icon {
    height: 12px;
    width: 12px;
}

.card-box-wrapper {
    + .card-box-wrapper {
        border-left: 1px solid $color-gray;
    }
}

.graphLabel {
    color: #868686;
    font-size: 15px;
    margin-top: -10px;
    text-align: center;
}

.custom-card {
    &.fix-height {
        height: 100%;
        max-height: 360px;
        .custom-card-body {
            overflow: hidden;
            height: 100%;
            max-height: calc(100% - 40px);
            overflow-y: auto;
            position: relative;
            .block-bg-animate-wrap{
                position: absolute;
                top: 0;
                left: calc(50% - 0.5rem);
                width: 1rem;
                height: 100%;
                box-shadow: 0 5px 20px -5px rgba(255, 255, 255, 0.14);
                overflow: hidden;
                > div{
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(0deg,hsla(0,0%,100%,.65),hsla(0,0%,100%,.65)),#c2c9d1;
                    box-shadow: 0 -5px 20px -5px rgba(16, 16, 16, 14%), inset 0 -3px 5px hsla(0deg, 0%, 100%, 70%), inset 0 3px 4px hsla(0deg, 0%, 100%, 70%); 
                }
                .slider-move{
                    width: 3rem;
                    height: 1rem;
                    background: url(../../images/slider.svg);
                    transform: rotate(90deg) translateY(16px);
                    animation-name: slider-move;
                    animation-duration: 2s;
                    animation-iteration-count: infinite;
                    animation-timing-function: linear;
                    position: absolute;
                }
                @keyframes slider-move {
                    0%{top: 0;}
                    100%{top: 100%;}
                }
            }
        }
    }
    
}

.thumb-horizontal {
    background-color: #808080b0;
    border-radius: 10px;
}

.block-link {
    width: 16px;
    height: 16px;
    margin-right: 5px;
}


@media (max-width: 1199px) {
    .pricing-box {
        .top-part {
            .card-box-wrapper {
                &:nth-child(even) {
                    border-left: none;
                }
            }
        }
        .bottom-part {
            .card-box-wrapper {
                &:nth-child(odd) {
                    border-left: none;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .pricing-box {
        border-right: none;
        .top-part {
            .card-box-wrapper {
                .card-box {
                    padding-bottom: 16px;
                    border-bottom: 1px solid $color-gray;
                }
                &:last-child {
                    .card-box {
                        padding-bottom: 0;
                        border-bottom: none;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    // .custom-card {
    //     &.fix-height {
    //         max-height: 70vh;
    //         .custom-card-body {
    //             height: calc(70vh - 40px);
    //         }
    //     }
    // }
    .pricing-box {
        .top-part {
            .left-info,
            .right-info {
                width: 100%;
            }
        }
        .bottom-part {
            .card-box-wrapper {
                border: none;
                .card-box {
                    padding-bottom: 16px;
                    border-bottom: 1px solid $color-gray;
                }
                &:last-child {
                    .card-box {
                        padding-bottom: 0;
                        border-bottom: none;
                    }
                }
            }
        }
    }
}
