@import "../common/variables";
.header {
    position: sticky;
    top: 0;
    z-index: 9;
}

.headerlogo {
    .logo {
        width: 100px;
        height: auto;
    }
}

@media(max-width: 767px) {
    .mein-menu {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: $color-maya;
        width: 250px;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        transition: 0.2s ease-in;
        -o-transition: 0.2s ease-in;
        -ms-transition: 0.2s ease-in;
        -moz-transition: 0.2s ease-in;
        -webkit-transition: 0.2s ease-in;
        &:before {
            position: absolute;
            content: "";
            background-color: $color-blue;
            opacity: 0.5;
            width: 100vw;
            height: 100%;
            left: 100%;
            top: 0;
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
            transition: 0.2s ease-in;
            -o-transition: 0.2s ease-in;
            -ms-transition: 0.2s ease-in;
            -moz-transition: 0.2s ease-in;
            -webkit-transition: 0.2s ease-in;
        }
        &.show-menu {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            &:before {
                -webkit-transform: translateX(0);
                transform: translateX(0);
            }
        }
    }
    .menu-close {
        top: 0;
        right: 0;
        height: 30px;
        width: 30px;
    }
}