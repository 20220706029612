@font-face {
    font-family: 'Lato Hairline';
    src: url('../../fonts/Lato-Hairline.eot');
    src: url('../../fonts/Lato-Hairline.eot?#iefix') format('embedded-opentype'), url('../../fonts/Lato-Hairline.woff2') format('woff2'), url('../../fonts/Lato-Hairline.woff') format('woff'), url('../../fonts/Lato-Hairline.ttf') format('truetype'), url('../../fonts/Lato-Hairline.svg#Lato-Hairline') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('../../fonts/Lato-Regular.eot');
    src: url('../../fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'), url('../../fonts/Lato-Regular.woff2') format('woff2'), url('../../fonts/Lato-Regular.woff') format('woff'), url('../../fonts/Lato-Regular.ttf') format('truetype'), url('../../fonts/Lato-Regular.svg#Lato-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('../../fonts/Lato-Black.eot');
    src: url('../../fonts/Lato-Black.eot?#iefix') format('embedded-opentype'), url('../../fonts/Lato-Black.woff2') format('woff2'), url('../../fonts/Lato-Black.woff') format('woff'), url('../../fonts/Lato-Black.ttf') format('truetype'), url('../../fonts/Lato-Black.svg#Lato-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('../../fonts/Lato-Light.eot');
    src: url('../../fonts/Lato-Light.eot?#iefix') format('embedded-opentype'), url('../../fonts/Lato-Light.woff2') format('woff2'), url('../../fonts/Lato-Light.woff') format('woff'), url('../../fonts/Lato-Light.ttf') format('truetype'), url('../../fonts/Lato-Light.svg#Lato-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('../../fonts/Lato-Bold.eot');
    src: url('../../fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'), url('../../fonts/Lato-Bold.woff2') format('woff2'), url('../../fonts/Lato-Bold.woff') format('woff'), url('../../fonts/Lato-Bold.ttf') format('truetype'), url('../../fonts/Lato-Bold.svg#Lato-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}