.main-widget-div {
  border: 1px solid #e3e4e7;
  width: 48%;
  height: 150px;
  padding: 20px 20px 50px 20px;
  border-radius: 10px;
  border-bottom: 4px solid #073d83;
  cursor: pointer;
}

.secodary-blue-widget {
  border-bottom: 4px solid #007de4 !important;
}

.grey-widget {
  border-bottom: 4px solid #4c4f53 !important;
}

.green-widget {
  border-bottom: 4px solid #26ae6c !important;
}

.title {
  font-size: 17px;
  color: black;
}

.value {
  font-size: 26px;
  margin-bottom: 0;
}

.sub-value {
  font-size: 13px;
  margin-bottom: 0px;
}

.primary-blue {
  color: #073d83;
}

.secondary-blue {
  color: #007de4;
}

.green-clr {
  color: #26ae6c;
}

.widget-sec-title {
  font-weight: 600;
  font-size: 20px;
}

.font-weight-bld {
  font-weight: 600;
}

.block-number-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.total-transaction {
  font-size: 12.5px;
  font-weight: 400;
}

.dt {
  font-size: 11px;
  font-weight: 600;
}
