$color-blue:#073D83;
$color-white:#FFFFFF;
$color-lightgray:#F8F8F8;
$color-gray:#DADCE0;
$color-lightblue:#007DE4;
$color-darkgray:#868686;
$color-red:#CB0505;
$color-green:#009900;
$color-dark:#0D0F12;
$color-navtext:#161616;
$color-maya:#E8F0FE;
$color-darktext:#4C4F53;
$color-blueoverlay:rgba(7, 61, 131, 0.1)