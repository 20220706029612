.page-title {
    font-size: 30px;
    line-height: 36px;
}

.f-thin {
    font-weight: 300;
}

.f-regular {
    font-weight: normal;
}

.f-black {
    font-weight: 900;
}

.f-bold {
    font-weight: bold;
}

.f-30px {
    font-size: 30px;
    line-height: 36px;
}

.f-24px {
    font-size: 24px;
    line-height: 30px;
}

.f-20px {
    font-size: 20px;
    line-height: 26px;
}

.f-16px {
    font-size: 16px;
    line-height: 22px;
}

.f-14px {
    font-size: 14px;
    line-height: 20px;
}

.f-12px {
    font-size: 12px;
    line-height: 18px;
}

@media(max-width: 1199px) {
    .f-30px {
        font-size: 26px;
        line-height: 32px;
    }
    .f-24px {
        font-size: 22px;
        line-height: 28px;
    }
    .f-20px {
        font-size: 18px;
        line-height: 24px;
    }
}

@media(max-width: 767px) {
    .f-30px {
        font-size: 24px;
        line-height: 30px;
    }
    .f-24px {
        font-size: 20px;
        line-height: 26px;
    }
}