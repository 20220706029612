.searchbar {
    padding: 70px 0;
    .searchbar-backgound {
        top: 0;
        left: 0;
        height: 317px;
        z-index: -1;
        &:before {
            position: absolute;
            content: "";
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            background: url(../../images/searchbar-bg-shape.svg) no-repeat left top;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
        }
    }
    .dropdown-lightgray {
        .dropdown-toggle {
            border-radius: 10px 0 0 10px;
        }
    }
    .search-wrapper {
        .custom-button {
            min-width: 150px;
            border-radius: 0 10px 10px 0;
            height: 100%;
        }
        .dropdown-custom {
            .dropdown-menu {
                &.show {
                    -webkit-transform: translate3d(0px, 49px, 0px) !important;
                    transform: translate3d(0px, 49px, 0px) !important;
                }
            }
        }
    }
}

@media(max-width: 1199px) {
    .searchbar {
        padding: 50px 0;
    }
}

@media(max-width: 767px) {
    .searchbar {
        .search-wrapper {
            .custom-button {
                min-width: 50px;
            }
        }
    }
}