@import "../common/variables";
.nav-tabs {
    border-bottom: 1px solid $color-gray;
    .nav-item {
        cursor: pointer;
        &.show {
            .nav-link {
                color: $color-lightblue;
                border-color: $color-gray $color-gray $color-white;
            }
        }
    }
    .nav-link {
        color: $color-dark;
        &.active {
            color: $color-lightblue;
            border-color: $color-gray $color-gray $color-white;
        }
        &:focus,
        &:hover {
            border-color: $color-gray $color-gray $color-gray;
        }
    }
}

@media(max-width: 767px) {
    .nav-tabs {
        .nav-link {
            padding: 5px 12px;
        }
    }
}