.custom-table {
    .table-row {
        .label {
            width: 210px;
        }
        .detail {
            width: calc(100% - 210px);
        }
    }
}

.data-table {
    overflow: hidden;
    overflow-x: auto;
    table {
        tr {
            th {
                &:first-child {
                    min-width: 100px;
                }
            }
        }
    }
}

@media(max-width: 1199px) {
    .data-table {
        padding-bottom: 10px;
        table {
            min-width: 800px;
            tr {
                th {
                    &:first-child {
                        min-width: 100px;
                    }
                }
            }
        }
    }
}

@media(max-width: 767px) {
    .custom-table {
        .table-row {
            .label,
            .detail {
                width: 100%;
            }
        }
    }
}