body {
    margin: 0;
    padding: 0;
    font-family: 'Lato';
    font-weight: normal;
    color: $color-darktext;
}

.transiton-02s {
    transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    -ms-transition: 0.2s ease-in;
    -moz-transition: 0.2s ease-in;
    -webkit-transition: 0.2s ease-in;
}

img {
    max-width: 100%;
}

ul {
    padding: 0;
    margin: 0;
}

li {
    list-style: none;
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
}

.site-main {
    padding-bottom: 70px;
}

.dark-color {
    color: $color-dark;
}

.border-1px {
    border: 1px solid;
}

.borderRight-1px {
    border-right: 1px solid;
}

.borderLeft-1px {
    border-left: 1px solid;
}

.border-bottom-1px {
    border-bottom: 1px solid;
}

.border-top-1px {
    border-top: 1px solid;
}

.border-gray {
    border-color: $color-gray;
}

.radius-5 {
    border-radius: 5px;
}

.radius-10 {
    border-radius: 10px;
}

.white-color {
    color: $color-white;
}

.navtext-color {
    color: $color-navtext;
}

.darkgray-color {
    color: $color-darkgray;
}

.red-color {
    color: $color-red;
}

.green-color {
    color: $color-green;
}

.lightblue-color {
    color: $color-lightblue;
}

.darktext-color {
    color: $color-darktext;
}

.blue-color {
    color: $color-blue;
}

.white-bg {
    background-color: $color-white;
}

.blue-bg {
    background-color: $color-blue;
}

.lightblue-bg {
    background-color: $color-lightblue;
}

.maya-bg {
    background-color: $color-maya;
}

.lightgray-bg {
    background-color: $color-lightgray;
}

.blueoverlay-bg {
    background-color: $color-blueoverlay;
}

.fill-white {
    fill: $color-white;
}

.hover-blue-color {
    @extend .transiton-02s;
    &:hover {
        color: $color-blue;
    }
}

.hover-blue-bg {
    @extend .transiton-02s;
    &:hover {
        background-color: $color-blue;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.dropdown-custom {
    .dropdown-toggle {
        outline: none;
        box-shadow: none;
        padding: 5px 20px;
        min-width: 145px;
        text-align: left;
        position: relative;
        &::after {
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            right: 20px;
        }
    }
    &.show> {
        .btn-secondary {
            &.dropdown-toggle {
                outline: none;
                box-shadow: none;
                &:hover,
                &:focus,
                &:active {
                    outline: none;
                    box-shadow: none;
                }
            }
        }
    }
    .dropdown-menu {
        &.show {
            // right: 0 !important;
            // left: auto;
            -webkit-transform: translate3d(0px, 36px, 0px) !important;
            transform: translate3d(0px, 36px, 0px) !important;
            width: fit-content;
        }
    }
}

.sticky-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 9;
}

.dropdown-blue {
    .dropdown-toggle {
        background-color: $color-lightblue;
        border-color: $color-lightblue;
    }
    &.show {
        > {
            .btn-secondary {
                &.dropdown-toggle {
                    background-color: $color-lightblue;
                    border-color: $color-lightblue;
                }
            }
        }
    }
}

.dropdown-lightgray {
    .dropdown-toggle {
        padding: 12px 20px;
        background-color: $color-lightgray;
        border-color: $color-lightgray;
        color: $color-dark;
    }
    &.show {
        > {
            .btn-secondary {
                &.dropdown-toggle {
                    background-color: $color-lightgray;
                    border-color: $color-lightgray;
                    color: $color-dark;
                }
            }
        }
    }
}

.custom-button {
    outline: none;
    box-shadow: none;
    border: 1px solid;
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.lightblue-button {
    border-color: $color-lightblue;
    &:hover,
    &:focus {
        background-color: $color-lightblue;
        border-color: $color-white;
    }
}

.form-control {
    outline: none;
    box-shadow: none;
    border: none;
    &:hover,
    &:focus,
    &:active {
        outline: none;
        box-shadow: none;
    }
}

.custom-card-block {
    +.custom-card-block {
        margin-top: 16px;
    }
    .block-row {
        +.block-row {
            margin-top: 5px;
        }
    }
}

@media(min-width: 991px) {
    .border-top-lg-none {
        border-top: none;
    }
}

@media(min-width: 1400px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1344px;
    }
}

@media(max-width: 767px) {
    .dropdown-custom {
        .dropdown-menu {
            &.show {
                -webkit-transform: translate3d(16px, 36px, 0px) !important;
                transform: translate3d(16px, 36px, 0px) !important;
            }
        }
    }
    .dropdown-lightgray {
        .dropdown-toggle {
            min-width: 90px;
            padding: 8px 15px;
        }
    }
}

@media(max-width: 576px) {
    .site-main {
        padding-bottom: 100px;
    }
}